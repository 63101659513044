import 'bootstrap/dist/css/bootstrap.css'
import "bootstrap"
import './assets/main.css'
import 'floating-vue/dist/style.css'

async function setup() {
    const { app, router, store, Vidle } = await (await import("./main")).default()

    // wait until router is ready before mounting to ensure hydration match
    router.isReady().then(() => {
        app.mount('#app')
    })
}

setup()
